<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <span>角色名称：</span>
                    <el-input v-model="roleName" size="small" placeholder="请输入角色名称"
                              class="handle-input mr10"></el-input>
                    <!--<span>部门名称查询：</span>
                    <el-input v-model="roleDepart" size="small"  placeholder="请输入角色名称" class="handle-input mr10"></el-input>
                    -->
                    <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtu">搜索</el-button>
                    <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置</el-button>
                    <el-button type="primary" icon="el-icon-circle-plus" size="small"
                               v-if="hasAuthority('sys_role_add')" @click="addRole">增加
                    </el-button>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                                :border="true" @selection-change="chooseSelection">
                            <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
                            <el-table-column prop="roleCode" label="角色编码" width="360" align="center"></el-table-column>
                            <el-table-column prop="roleName" label="角色名称" width="360" align="center"></el-table-column>
                            <el-table-column prop="description" label="描述" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" label="操作" width="100" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑"
                                           v-if="hasAuthority('sys_role_update')"
                                           @click="editRole(scope.row)"></i>
                                        <i class="iconfont iconshanchu" title="删除"
                                           v-if="hasAuthority('sys_role_delete')"
                                           @click="deleteRole(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
            <div class="dialog">
                <el-dialog :title="title"
                           :visible.sync="dialogVisible"
                           :close-on-click-modal="isClose"
                           width="40%"
                           :show-close="false"
                           center>
                    <div class="dialog-body">
                        <div class="form-data">
                            <div class="form-title">
                                <span>基础信息录入</span>
                            </div>
                            <el-form :model="roleForm" :rules="rules" ref="roleForm" label-width="100px">
                                <el-form-item label="角色名称" prop="roleName">
                                    <el-input v-model="roleForm.roleName"></el-input>
                                </el-form-item>
                                <el-form-item label="角色编码" prop="roleCode">
                                    <el-input v-if="type == 'save'" v-model="roleForm.roleCode"></el-input>
                                    <el-input v-else disabled v-model="roleForm.roleCode" readonly></el-input>
                                </el-form-item>
                                <el-form-item label="描述" prop="description">
                                    <el-input v-model="roleForm.description"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="choose">
                            <div class="form-title">
                                <span>权限配置</span>
                            </div>
                            <el-tree
                                    :data="data"
                                    show-checkbox
                                    node-key="menuCode"
                                    ref="tree"
                                    highlight-current
                                    :default-expanded-keys="expandIds"
                                    :default-checked-keys="checkIds"
                                    :props="defaultProps">
                            </el-tree>
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="resetForm('roleForm')">取 消</el-button>
                        <el-button type="primary" @click="saveRole('roleForm',type)">确 定</el-button>
                    </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    import tableData from "../../../public/json/role.json"
    import menus from "../../../public/json/menu.json"

    export default {
        name: "Role",
        data() {
            return {
                pageList: [16, 20, 30, 50],
                tableHeight: '',
                data: [],
                current: 1,
                defaultProps: {
                    children: 'childrenList',
                    label: 'menuName'
                },
                checkIds: [],
                expandIds: [],
                title: '',
                lineHeight: '',
                tableData: [],
                roleName: '',
                roleDepart: '',
                dialogVisible: false,
                multipleSelection: [],
                delList: [],
                isClose: false,
                type: '',
                // totalPage:0,
                totalPage: 30,
                currentPage: 1,
                option: {
                    pageIndex: 1,
                    pageSize: 16,
                },
                roleForm: {
                    roleName: '',
                    roleCode: '',
                    description: ''
                },
                rules: {
                    roleName: [
                        {required: true, message: '请输入角色名称', trigger: 'blur'},
                    ],
                    roleCode: [
                        {required: true, message: '请输入角色编码', trigger: 'blur'},
                    ],
                }
            }
        },
        created() {
            this.searchBtu();
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        },
        methods: {
            pageChange(option) {
                this.option.pageIndex = option
                this.currentPage = option
                this.$api.pageRoles({
                    roleName: this.roleName,
                    pageIndex: option,
                    pageSize: 16
                }).then(
                    res => {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                )
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pageRoles({
                    roleName: this.roleName,
                    pageIndex: 1,
                    pageSize: val
                }).then(
                    res => {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                )
            },
            chooseSelection(val) {
                this.multipleSelection = val;
            },
            editRole(row) {
                this.checkIds = [];
                this.title = '编辑角色信息'
                this.type = "edit";
                this.dialogVisible = true;
                this.$api.getPermission({id: row.id}).then(
                    res => {
                        this.data = res.data.menuList;
                        this.ergodic(this.data)
                    }
                )
                this.roleForm = {
                    id: row.id,
                    roleName: row.roleName,
                    roleCode: row.roleCode,
                    description: row.description,
                    permissionsStr: ''
                }
            },
            ergodic(data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].childrenList != null) {
                        this.ergodic(data[i].childrenList);
                    } else {
                        if (data[i].isChecked) {
                            this.checkIds.push(data[i].menuCode)
                        }
                    }
                }
            },
            deleteRole(id) {
                this.$confirm('你确定要删除角色信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteRoles({id}).then(
                        res => {
                            if (res.code == 200) {
                                this.searchBtu()
                                this.$message.success(res.message)
                            } else {
                                this.$message.error(res.message)
                            }
                        }
                    )
                }).catch(() => {
                })
            },
            searchBtu() {
                this.pageChange(1)
            },
            reset() {
                this.roleName = '';
                this.pageChange(1);
            },
            addRole() {
                this.title = '新增角色信息'
                this.type = "save";
                this.dialogVisible = true
                this.$api.getPermission({id: null}).then(
                    res => {
                        this.data = res.data.menuList;
                    }
                )
                this.roleForm = {
                    roleName: '',
                    departName: '',
                    superDepart: '',
                    description: '',
                    permissionsStr: ''
                }
            },

            saveRole(formName, type) {
                var treeList = this.$refs.tree.getCheckedNodes(false, true).map(i => i.menuCode);
                this.roleForm.permissionsStr = treeList.join("|");
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (type == 'save') {
                            this.$api.saveRoles(this.roleForm).then(
                                res => {
                                    if (res.code == 200) {
                                        this.searchBtu()
                                        this.dialogVisible = false;
                                        this.$message.success(res.message)
                                        this.$refs.tree.setCheckedKeys([]);
                                        this.$refs[formName].resetFields();
                                    } else {
                                        this.$message.error(res.message)
                                        this.$refs.tree.setCheckedKeys([]);
                                    }
                                }
                            )
                        } else {
                            this.$api.updateRoles(this.roleForm).then(
                                res => {
                                    if (res.code == 200) {
                                        this.pageChange(this.currentPage)
                                        this.dialogVisible = false;
                                        this.$message.success(res.message)
                                        this.$refs.tree.setCheckedKeys([]);
                                    } else {
                                        this.$message.error(res.message)
                                        this.$refs.tree.setCheckedKeys([]);
                                    }
                                }
                            )
                        }
                    }
                })
            },
            resetForm(formName) {
                this.dialogVisible = false
                this.$refs[formName].resetFields();
                this.$refs.tree.setCheckedKeys([]);
            },
        },
    }
</script>

<style lang="scss" scoped>

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .iconbianji {
        margin-right: 10px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 10px;
        color: red;
    }

    .dialog-body {
        display: flex;
        width: 100%;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .handle-box {
        padding: 5px;
        margin-left: 10px;
    }

    .form-title {
        font-family: 宋体;
        font-weight: bolder;
        font-size: 24px;
        text-align: center;
        margin-bottom: 2%;
    }

    .form-data {
        /*border: 1px red solid;*/
        width: 50%;
    }

    .choose {
        /* border: 1px blue solid;*/
        width: 50%;
        margin-left: 7%;
    }

    .page-body {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 5%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }
</style>
